export const BLOATED_MESSAGES = [
  // GroupMonitorData
  11025,
  // AgentMonitorData
  11027,
  11028,
  11029,
  //Picklist
  11060,
  11061,
  11068,
  11069,
];