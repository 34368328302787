import {Socket} from './Socket';

export class MessageQueue {
  private static messageQueue = [];

  static sendQueue() {
    this.messageQueue = this.messageQueue
      .map(msg => {
        try {
          Socket.Socket.json(msg);
          return null;
        } catch (e) {
          return msg;
        }
      })
      .filter(emtpy => emtpy !== null);
  }

  static get Queue() {
    return this.messageQueue;
  }
}