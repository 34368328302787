import type MultichannelSdk from '@multichannel/sdk/src/api';
import { EVENT_LIST } from '@multichannel/sdk/src/api/constants';
import Sockette from 'sockette';
import { v4 as uuidv4 } from 'uuid';
import Events from '@core/events';

export class Socket {
  private static socket = null;

  static initSocket(sdk: MultichannelSdk, handle: (rawMessage: MessageEvent) => void) {
    this.socket = new Sockette(`${sdk.config.urls.getAgentServerFor(sdk.services?.activeService)}?connection_id=${uuidv4()}`, {
      maxAttempts: 10,
      timeout    : 3000,
      onopen     : () => {
        Events.trigger(EVENT_LIST.SDK_NETWORK_SOCKET_ONLINE);
        sdk.debug('api.messageHandler.socket').trace('Connected!');
      },
      onmessage: e => {
        handle(e);
      },
      onreconnect: () => {
        sdk.debug('api.messageHandler.socket').trace('Reconnecting socket!');
      },
      onmaximum: () => {
        sdk.debug('api.messageHandler.socket').trace('Maximum reconnects reached');
        Events.trigger(EVENT_LIST.SDK_NETWORK_RECONNECT_MAXIMUM_REACHED);
        // TODO: Find a place where this dont crash everything
        // sdk.actions.invoke('sdk.urls.burn');
      },
      onclose: e => {
        if (e.code !== 1000) {
          Events.trigger(EVENT_LIST.SDK_NETWORK_SOCKET_LOST);
          return sdk.debug('api.messageHandler.socket').error('socketClosed unexpected!', {
            code    : e?.code || '',
            wasClean: e?.wasClean || false,
            reason  : e?.reason || ''
          });
        }
        sdk.debug('api.messageHandler.socket').trace('socketClosed!', {
          code    : e?.code || '',
          wasClean: e?.wasClean || false,
          reason  : e?.reason || ''
        });
      },
      onerror: () => {
        Events.trigger(EVENT_LIST.SDK_NETWORK_SOCKET_ERROR);
        sdk.debug('api.messageHandler.socket').error('Closed!');
      },
    });
  }

  static get Socket() {
    return this.socket;
  }
}